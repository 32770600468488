@charset "UTF-8";
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: loadingLM infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*bottom_blue_btn*/
.notch_padding {
  padding: 15px 0 calc(constant(safe-area-inset-bottom) + 15px);
  padding: 15px 0 calc(env(safe-area-inset-bottom) + 15px);
}
body {
  padding-right: constant(safe-area-inset-right);
  padding-left: constant(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
}

/*background-leanier*/
.skeleton-bg {
  background:
  
  /* linear-gradient(
      90deg,
      #eaebec -7.87%,
      rgba(255, 255, 255, 0.53) 107.47%
    ), */ #e5e6e7;
}
/*hand-matrix*/
.tutohand1 {
  transform: rotate(0deg);
}
.tutohand2 {
  transform: rotate(-30deg);
}
.tutohand34 {
  transform: rotate(-15deg);
}

/*font*/
@import url("https://webfontworld.github.io/pretendard/Pretendard.css");

.caption_2 {
  font-family: "Pretendard";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.caption_1_400 {
  font-family: "Pretendard";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.caption_1_600 {
  font-family: "Pretendard";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}
.body_1_400 {
  font-family: "Pretendard";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.body_1_400_jangrucont {
  font-family: "Pretendard";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}
.body_1_600 {
  font-family: "Pretendard";
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.button_700 {
  font-family: "Pretendard";
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
.subtitle_2 {
  font-family: "Pretendard";
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.subtitle_1_400 {
  font-family: "Pretendard";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
.subtitle_1_600 {
  font-family: "Pretendard";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.subtitle_1_700 {
  font-family: "Pretendard";
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}
.h3_800 {
  font-family: "Pretendard";
  font-weight: 800;
  font-size: 20px;
  line-height: 26px;
}
.h2_800 {
  font-family: "Pretendard";
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
}
.h1_700 {
  font-family: "Pretendard";
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;200;300;400;500;600;700&display=swap");
.montserrat_700 {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 700;
}

/*swiper*/
/*swiper color*/

.mainbanner .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  top: inherit;
}
.mainbanner .swiper-pagination-bullet-active {
  background: #f7ce4b !important;
  width: 8px;
  border-radius: 50%;
}
.mainbanner .swiper-pagination-bullet {
  background: #e5e6e7;
  opacity: 100%;
}

.tutorial .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: inherit;
  top: -10px;
}

.tutorial .swiper-pagination-bullet-active {
  border-radius: 12px;
  width: 28px;
  background: #1b5edc !important;
}

.tutorial .swiper-pagination-bullet {
  background: #cbdffc;
  opacity: 100%;
}

.tutorial
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 6px;
}
/*select*/
select {
  -webkit-appearance: none; /* for chrome */

  -moz-appearance: none; /*for firefox*/

  appearance: none;
  background: url("../../public/images/mo/down.svg") no-repeat 97% 50%/15px auto;
}

select::-ms-expand {
  display: none; /*for IE10,11*/
}

/*placeholder*/
.login::placeholder {
  color: #999ca3;
  font-family: "Pretendard";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  word-break: keep-all;
}
.surveycheck::placeholder {
  color: #999ca3;
  font-family: "Pretendard";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  word-break: keep-all;
}
.dataedit::placeholder {
  color: #999ca3;
  font-family: "Pretendard";
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  word-break: keep-all;
}

input::placeholder,
textarea::placeholder {
  color: #999ca3;
  font-family: "Pretendard";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  word-break: keep-all;
}

/*zindex*/
.z--1 {
  z-index: -1;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}
.z-5 {
  z-index: 5;
}
.z-6 {
  z-index: 6;
}
.z-7 {
  z-index: 7;
}
.z-8 {
  z-index: 8;
}
.z-9 {
  z-index: 9;
}
.z-60 {
  z-index: 60;
}
.z-70 {
  z-index: 70;
}
.z-80 {
  z-index: 80;
}
.z-90 {
  z-index: 90;
}
.z-100 {
  z-index: 100;
}
/* width */
.w-1-9 {
  width: 11.111111%;
}
.w-2-9 {
  width: 22.222222%;
}
.w-3-9 {
  width: 33.333333%;
}
.w-4-9 {
  width: 44.444444%;
}
.w-5-9 {
  width: 55.555555%;
}
.w-6-9 {
  width: 66.666666%;
}
.w-7-9 {
  width: 77.777777%;
}
.w-8-9 {
  width: 88.888888%;
}
.w-9-9 {
  width: 99.999999%;
}
.w-1-7 {
  width: 14.2857142%;
}

/* transform 50% */
.transform-50 {
  transform: translate(-50%, -50%);
}
.transform-x-50 {
  transform: translateX(-50%);
}
.transform-y-50 {
  transform: translateY(-50%);
}

/*main-backgroundimage*/
.banner1 {
  background-image: url("../../public/images/mo/pngjpg/banner1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.banner2 {
  background-image: url("../../public/images/mo/pngjpg/banner2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.banner3 {
  background-image: url("../../public/images/mo/pngjpg/banner3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.banner4 {
  background-image: url("../../public/images/mo/pngjpg/힐리어리 배너.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/************animations*/
/*loading-animation*/
.main_imagechange {
  animation: loadingLM 0.5s linear forwards;
}
@keyframes loadingLM {
  from {
    background-image: url("../../public/images/mo/pngjpg/pocketcover.png");
  }
  to {
    background-image: url("../../public/images/mo/pngjpg/pocketboycover.png");
  }
}
.basic-rotate {
  animation: rotateLM 3s linear infinite;
}
@keyframes rotateLM {
  from {
    background-image: url("../../public/images/mo/pocketcover.svg");
    background-size: contain;
    transform: rotate(0);
  }
  to {
    background-image: url("../../public/images/mo/pocketcover.svg");
    background-size: contain;
    transform: rotate(360deg);
  }
}

/*더보기-animation*/
.moredown {
  animation: down 0.5s 1 ease forwards;
}

@keyframes down {
  from {
    bottom: 0;
  }
  to {
    bottom: -1200px;
  }
}
.moreup {
  animation: up 0.5s 1 ease forwards;
}

@keyframes up {
  from {
    bottom: -1200px;
  }
  to {
    bottom: 0px;
  }
}
/*alarm-animation*/
.alarmup {
  animation: alup 1s 1 ease forwards;
}
@keyframes alup {
  from {
    top: 0px;
  }
  to {
    top: -100px;
  }
}

.alarmdown {
  animation: aldown 1s 1 ease forwards;
}
@keyframes aldown {
  from {
    top: -100px;
  }
  to {
    top: 0px;
  }
}
/*notice-animation*/
.noticeup {
  animation: noup 0.5s 1 ease forwards;
}

@keyframes noup {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

.noticedown {
  animation: nodown 0.5s 1 ease forwards;
}

@keyframes nodown {
  from {
    height: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
/*diary-animation*/
.waterup {
  animation: waup 0.5s 1 ease forwards;
}
.cupimage {
  background-image: url("../../public/images/mo/cup.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
@keyframes waup {
  0% {
    transform: translateX(-50%) scaleY(0);
    transform-origin: bottom;
  }
  100% {
    transform: translateX(-50%) scaleY(1);
    transform-origin: bottom;
  }
}
.waterdown {
  animation: wadown 0.5s 1 ease forwards;
}

@keyframes wadown {
  0% {
    transform: translateX(-50%) scaleY(1);
    transform-origin: bottom;
  }
  100% {
    transform: translateX(-50%) scaleY(0);
    transform-origin: bottom;
  }
}

.poopimage {
  background-image: url("../../public/images/mo/stomapack.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.poopup13 {
  animation: poup13 0.5s 1 ease forwards;
}

@keyframes poup13 {
  0% {
    transform: translateX(-50%) scaleY(0);
    transform-origin: bottom;
  }
  100% {
    transform: translateX(-50%) scaleY(1);
    transform-origin: bottom;
  }
}
.poopup23 {
  animation: poup23 0.5s 1 ease forwards;
}

@keyframes poup23 {
  0% {
    transform: translateX(-50%) scaleY(0);
    transform-origin: bottom;
  }
  100% {
    transform: translateX(-50%) scaleY(1);
    transform-origin: bottom;
  }
}
.poopdown13 {
  animation: podown13 0.5s 1 ease forwards;
}

@keyframes podown13 {
  0% {
    transform: translateX(-50%) scaleY(1);
    transform-origin: bottom;
  }
  100% {
    transform: translateX(-50%) scaleY(0);
    transform-origin: bottom;
  }
}
.poopdown23 {
  animation: podown23 0.5s 1 ease forwards;
}

@keyframes podown23 {
  0% {
    transform: translateX(-50%) scaleY(1);
    transform-origin: bottom;
  }
  100% {
    transform: translateX(-50%) scaleY(0);
    transform-origin: bottom;
  }
}
/*backgraound water poop img*/
.water {
  background-image: url("../../public/images/mo/water.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  transform: translateX(-50%) scaleX(0.9);
  top: 13px;
  left: 29px;
}

/*핸드폰가로모드*/
@media (orientation: portrait) {
  .horizontal {
    position: inherit;
  }
}
@media (orientation: landscape) {
  .horizontal {
    transform: rotate(0deg);
    transform-origin: top left;
    position: fixed;
    top: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90;
  }
}
/*word-break*/

.wordbreak-keepall {
  word-break: keep-all;
}

/*button*/
.solid_btn {
  background: #fafcff;
  border-color: #1b5edc;
  border: 1px;
  border-radius: 2px;
  transition: ease-in 1s;
}
.solid_btn:hover {
  background: #f2f6fc;
  border-color: #18428e;
  border: 1px;
  border-radius: 2px;
}
/*************library customs*/
/*toast css custom*/
.transparent {
  background-color: transparent !important;
}

.toastbottom {
  bottom: 4rem;
  justify-content: center !important;
  min-height: 73px !important;
  width: 100%;
  box-shadow: none !important;
}

.toastbottom div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Toastify__close-button {
  display: none !important;
}

/*react-chartjs-2 custom*/
.reportgraph {
  box-sizing: initial !important;
  /* width: initial !important; */
}

.weightgraph canvas,
.watergraph canvas,
.poopgraph canvas {
  padding: 0;
}
.chart-container canvas {
  height: 180px !important;
}

/*datepicker custom*/

.jangruchange > div {
  position: relative;
  width: 100%;
  z-index: 1;
}

.jangruchange .react-datepicker {
  border: none;
  border-radius: 0.5rem;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  padding: 12px;
  background-color: #f1f2f3;
}
.jangruchange .react-datepicker__header {
  border-top-left-radius: 0.5rem;
}
.jangruchange
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.5rem;
}
.jangruchange .react-datepicker__header {
  background-color: none;
  border-bottom: none;
}
.jangruchange .react-datepicker .react-datepicker__navigation--next {
  right: 235px;
}

.jangruchange .react-datepicker__navigation--previous {
  left: 3px;
}
.jangruchange .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: black !important;
  border-width: 2px 2px 0 0 !important;
}
.jangruchange .react-datepicker__portal .react-datepicker__current-month {
  position: absolute;
  left: 45px;
  top: -1px;
}
.jangruchange .react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1rem;
}
.jangruchange .react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: 400;
}
.jangruchange .react-datepicker__month {
  margin: 0;
  background-color: #fff;
}
.jangruchange .react-datepicker__navigation {
  top: 10px;
}
.jangruchange .react-datepicker__day-names {
  margin-bottom: -7px;
  margin-top: 14px;
}
.jangruchange .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.2rem;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 2rem;
  line-height: 2rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 10% !important;
  background-color: #679dee !important;
  color: #fff;
}

/*동영상 썸네일크기*/
video[poster] {
  /* 포스터 이미지의 크기를 비디오 영상에 꽉차도록 */
  height: 100%;
  width: 100%;
}
