.swiper {
  width: 100%;
  padding-bottom: 50px;
}

.swiper-pagination {
  height: 30px;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  margin-top: 300px;
}

.swiper-bullet {
  border-radius: 100%;
  background-color: #fefefe;
  opacity: 0.5;
  width: 10px;
  height: 10px;
  margin-top: 120px;
}

.swiper-bullet-active {
  background-color: #1b5edc;
  opacity: 1;
}

.swiper-bullet-section4 {
  border-radius: 100%;
  background-color: #fefefe;
  opacity: 0.5;
  width: 10px;
  height: 10px;
}

.swiper-bullet-section4-active {
  background-color: #1b5edc;
  opacity: 1;
}
