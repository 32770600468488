@import url("https://webfontworld.github.io/pretendard/Pretendard.css");

/* admin 전체 폰트 적용 */
.admin {
  font-family: "Pretendard";
}

/* Nav 버튼 글자색 그라데이션 적용 */
.navLabel.dark {
  animation: brightToDark 500ms linear;
}
.navLabel.bright {
  animation: darkToBright 500ms linear;
}

/* 헤더의 Nav버튼들 글자 색 그라데이션 (밝은 -> 어두운) */
@keyframes brightToDark {
  0%,
  45% {
    background: linear-gradient(0.25turn, #fafbfc, 0%, #020a1b);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  50% {
    background: linear-gradient(0.25turn, #fafbfc, 10%, #020a1b);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  75% {
    background: linear-gradient(0.25turn, #fafbfc, 50%, #020a1b);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  100% {
    background: linear-gradient(0.25turn, #fafbfc, 100%, #020a1b);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

/* 헤더의 Nav버튼들 글자 색 그라데이션 (어두운 -> 밝은) */
@keyframes darkToBright {
  0%,
  45% {
    background: linear-gradient(0.25turn, #020a1b, 0%, transparent);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  50% {
    background: linear-gradient(0.25turn, #020a1b, 10%, transparent);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  75% {
    background: linear-gradient(0.25turn, #020a1b, 50%, transparent);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  100% {
    background: linear-gradient(0.25turn, #020a1b, 100%, transparent);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

/* 버튼 눌렀을 때 효과 */
.navigationList.bright {
  background-color: #f4f5f6;
  border-radius: 48px 0px 0px 48px;
  color: #020a1b;
}
.navigationList.bright img {
  filter: invert(4%) sepia(15%) saturate(93%) hue-rotate(222deg) brightness(11%)
    contrast(102%);
}

.navigationList.dark {
  background-color: transparent;
  border-radius: 48px 0px 0px 48px;
  color: #fafbfc;
}
.navigationList.dark img {
  filter: invert(92%) sepia(2%) saturate(1%) hue-rotate(210deg) brightness(99%)
    contrast(97%);
}

.navIconList.bright {
  background-color: #f4f5f6;
  border-radius: 48px 0px 0px 48px;
}
.navIconList.bright img {
  filter: invert(4%) sepia(15%) saturate(93%) hue-rotate(222deg) brightness(11%)
    contrast(102%);
}

.navIconList.dark {
  background-color: transparent;
  border-radius: 48px 0px 0px 48px;
  color: #fafbfc;
}
.navIconList.dark img {
  filter: invert(92%) sepia(2%) saturate(1%) hue-rotate(210deg) brightness(99%)
    contrast(97%);
}

/* Paging 컴포넌트 */
.pagination {
  display: flex;
  height: 48px;
  padding: 8px 0;
  justify-content: center;
  column-gap: 4px;
}
.pagination li a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #020a1b !important;
  width: 32px;
  height: 32px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 28px;
  text-align: center;
}

.pagination li.active a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #020a1b !important;
  width: 32px;
  height: 32px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  border: 1px solid #020a1b;
  border-radius: 28px;
  text-align: center;
}
.pagination li a[aria-label="Go to previous page"] {
  color: transparent !important;
  border: none;
}
.pagination li a[aria-label="Go to next page"] {
  color: transparent !important;
  border: none;
}
.pagination li a[aria-label="Go to previous page"] {
  background: url("../../public/images/admin/paging_left_arrow.svg");
  background-repeat: no-repeat;
  background-size: 32px 32px;
  filter: brightness(0) saturate(100%) invert(5%) sepia(21%) saturate(3447%)
    hue-rotate(195deg) brightness(97%) contrast(104%);
}
.pagination li a[aria-label="Go to next page"] {
  background: url("../../public/images/admin/paging_right_arrow.svg");
  background-repeat: no-repeat;
  background-size: 32px 32px;
  filter: brightness(0) saturate(100%) invert(5%) sepia(21%) saturate(3447%)
    hue-rotate(195deg) brightness(97%) contrast(104%);
}
.pagination li.disabled a {
  cursor: default;
  filter: brightness(0) saturate(100%) invert(64%) sepia(8%) saturate(209%)
    hue-rotate(184deg) brightness(95%) contrast(93%);
}
.pagination li:last-child {
  visibility: hidden;
  border-radius: 0% 30% 30% 0%;
}
.pagination li:first-child {
  visibility: hidden;
  border-radius: 30% 0% 0% 30%;
}

/* Toast 팝업창 */
/* 공통 */
.admin .Toastify__toast-body {
  padding: 0 !important;
}

.toast_common {
  position: absolute;
  height: 43px;
  min-height: 43px !important;
  padding: 0px;
  margin: 0px;
  border-radius: 48px !important;
  font-family: Pretendard, sans-serif !important;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: #020a1b !important;
  background-color: rgba(203, 223, 252, 0.6) !important;
}

.toast_two_row {
  position: absolute;
  height: 70px;
  min-height: 70px !important;
  padding: 0px;
  margin: 0px;
  border-radius: 48px !important;
  font-family: Pretendard, sans-serif !important;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: #020a1b !important;
  background-color: rgba(203, 223, 252, 0.6) !important;
}

/* 로그인 페이지 관련 */
.toast_common.toast__login {
  background-color: rgba(203, 223, 252, 1) !important;
  width: 240px;
}

/* 검색 관련 */
.toast_common.toast__search {
  top: 80px;
  right: -32px;
  width: 250px;
}

/* 일반적인 경우 */
.toast_common.toast__normal {
  padding: 0 2px;
  width: 250px;
}

/* 장루 평가, 체크 페이지 */
.toast_common.toast__check {
  z-index: 2000;
}

/* FindId 아이디 찾았을 때 popup*/
.popup-content.findId-content {
  background-color: #cbdffc;
}

/* react-quill text height */
.quill {
  height: 500px;
}

/* react-quill font 설정 */
#toolbar-container .ql-font span[data-label="Nanum Gothic"]::before {
  font-family: "Nanum Gothic", sans-serif;
}
#toolbar-container .ql-font span[data-label="Nanum Myeongjo"]::before {
  font-family: "Nanum Myeongjo", serif;
}
#toolbar-container .ql-font span[data-label="Nanum Pen Script"]::before {
  font-family: "Nanum Pen Script", cursive;
}
#toolbar-container .ql-font span[data-label="Noto Sans KR"]::before {
  font-family: "Noto Sans KR", sans-serif;
}
#toolbar-container .ql-font span[data-label="Gamja Flower"]::before {
  font-family: "Gamja Flower", cursive;
}
.ql-font-nanum-gothic {
  font-family: "Nanum Gothic", sans-serif;
}
.ql-font-nanum-myeongjo {
  font-family: "Nanum Myeongjo", serif;
}
.ql-font-nanum-pen-script {
  font-family: "Nanum Pen Script", cursive;
}
.ql-font-noto-sans-KR {
  font-family: "Noto Sans KR", sans-serif;
}
.ql-font-gamja-flower {
  font-family: "Gamja Flower", cursive;
}

.ql-font.ql-picker {
  width: 150px !important;
}

/* 그래프 사진 모아보기 페이지의  사진 팝업 */
.imagePopoup__cancel path {
  width: 45px;
  height: 45px;
}
